<!-- 编辑弹窗 -->
<template>
  <div style="width: 100%">
    <div style="padding: 10px 0">
      <a-row :gutter="5" align="middle">
        <a-col> 地址：</a-col>
        <a-col>
          <a-input-search
            v-model:value="form.fullAddress"
            @search="search"
            enter-button
          />
        </a-col>
        <a-col> 坐标：</a-col>
        <a-col>
          <a-input
            v-model:value="form.lnglat"
            :disabled="true"
            style="background-color: #fff; color: #000; cursor: auto"
          />
        </a-col>
        <a-col>
          <a-button type="primary" @click="done">确定</a-button>
        </a-col>
      </a-row>
    </div>
    <div :id="id" style="width: 100%; height: 300px"></div>
  </div>
</template>
<script>
import AMap from 'AMap'
export default {
  name: 'AddressPicker',
  emits: ['done'],
  props: {
    // 修改回显的数据
    position: {},
    id: String,
    iconUrl: {
      type: String,
      default: '/static/point.png'
    }
  },
  components: {},
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.position, {}),
      map: null,
      marker: null
    }
  },
  watch: {
    position() {
      console.log('watch', this.position)
      if (this.position.longitude) {
        this.form = this.position
        this.form.lnglat =
        this.position.longitude + ',' + this.position.latitude

        const lnglat = new AMap.LngLat(
          this.position.longitude,
          this.position.latitude
        )
        this.addMarker(lnglat)
      } else {
        if (this.marker) {
          this.marker.setMap(null)
        }
        this.form = {}
      }
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    /* 保存编辑 */
    init() {
      const that = this
      that.map = new AMap.Map(this.id, {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
        that.map.addControl(new AMap.ToolBar())
        that.map.addControl(new AMap.Scale())
      })
      that.map.on('click', this.showInfoClick)
      if (that.position.longitude) {
        const lnglat = new AMap.LngLat(
          that.position.longitude,
          that.position.latitude
        )
        that.addMarker(lnglat)
      } else if (this.marker) {
        this.marker.setMap(null)
      }
    },
    search() {
      const that = this
      AMap.service(['AMap.PlaceSearch'], function () {
        const placeSearch = new AMap.PlaceSearch({
          pageIndex: 1
        })
        placeSearch.search(that.form.fullAddress, function (status, result) {
          const pois = result.poiList.pois
          if (pois) {
            const poi = pois[0]
            that.addMarker(poi.location)
            that.map.setCenter(poi.location)
          }
        })
      })
    },
    setForm(lnglat) {
      this.form.lnglat = lnglat.getLng() + ',' + lnglat.getLat()
      this.form.longitude = lnglat.getLng()
      this.form.latitude = lnglat.getLat()
    },
    addMarker(lnglat) {
      if (this.marker) {
        this.marker.setMap(null)
      }
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(32, 32),
        // 图标的取图地址
        image: this.iconUrl,
        imageOffset: new AMap.Pixel(0, 0),
        // 图标所用图片大小
        imageSize: new AMap.Size(32, 32)
      })
      this.marker = new AMap.Marker({
        icon: startIcon,
        position: lnglat,
        offset: new AMap.Pixel(-16, -40)
      })
      this.marker.setMap(this.map)
      this.setForm(lnglat)
    },
    showInfoClick(e) {
      this.addMarker(e.lnglat)
    },
    done() {
      this.$emit('done', this.form)
    }
  }
}
</script>

<style scoped>
</style>
