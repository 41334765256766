<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="680"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改充电柜' : '新建充电柜'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :md="24" :sm="24" :xs="24">
            <a-form-item label="充电柜编码:" name="cabinetCode">
              <a-input
                v-model:value="form.cabinetCode"
                placeholder="请输入充电柜编码"
                allow-clear
                :disabled="true"
              />
            </a-form-item>
            <a-form-item label="充电柜名称:" name="cabinetName">
              <a-input
                v-model:value="form.cabinetName"
                placeholder="请输入充电柜名称"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="所属运营商:" name="operatorId">
              <a-select
                v-model:value="form.operatorId"
                placeholder="请选择所属运营商"
                @change="changeOperator"
                allow-clear
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item>            
            <a-form-item label="换电柜状态:" name="state">
              <a-select
                v-model:value="form.state"
                placeholder="请选择电池状态"
                allow-clear
              >
                <a-select-option
                  v-for="item in batteryStateList"
                  :key="item.dictDataId"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属租赁点:" name="pointId">
              <a-select
                v-model:value="form.pointId"
                placeholder="请选择所属租赁点"
                allow-clear
              >
                <a-select-option
                  v-for="item in pointList"
                  :key="item.pointId"
                  :value="item.pointId"
                >
                  {{ item.pointName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="地图坐标:" name="coordinate">
              <a-button type="primary" @click="coordinate()">地图选点</a-button>
              <div>经度: {{ form.longitude }} 纬度: {{ form.latitude }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      :width="680"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      footer=""
    >
      <Map @done="setPosition" :position="position" id="cabinetAddress" />
    </a-modal>
  </div>
</template>

<script>
import * as chargeCabinetApi from '@/api/rnt/chargeCabinet'
import * as chargeCabinetModelApi from '@/api/rnt/chargeCabinetModel'
import * as pointListApi from '@/api/rnt/point'
import * as operatorApi from '@/api/rnt/operator'
import * as dictDataApi from '@/api/dictData/index'
import setting from '@/config/setting'
import Map from '@/components/map/addressPicker'
// import { PlusOutlined } from '@ant-design/icons-vue'
// import regions from '@/utils/regions'
export default {
  name: 'cabinetEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    // PlusOutlined
    Map
  },
  data() {
    return {
      startValue: null,
      fileList: [],
      batteryStateList: [],
      startOpen: false,
      endOpen: false,
      showPostion: false,
      position: {},
      // 表单数据
      pageData: {},
      headers: {},
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data, {
        modulesArray: [],
        indeterminate: false,
        checkAll: false
      }),
      // 编辑弹窗表单验证规则
      rules: {
        cabinetCode: [
          {
            required: true,
            message: '请输入充电柜编码',
            type: 'string',
            trigger: 'blur'
          }
        ],
        cabinetName: [
          {
            required: true,
            message: '请输入充电柜名称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        operatorId: [
          {
            required: true,
            message: '请选择所属运营商',
            type: 'number',
            trigger: 'blur'
          }
        ],
        model: [
          {
            required: true,
            message: '请选择型号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        brand: [
          {
            required: true,
            message: '请选择厂家',
            type: 'string',
            trigger: 'blur'
          }
        ],
        protocolType: [
          {
            required: true,
            message: '请选择换电柜协议',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 厂家list
      brandList: [],
      // 型号list
      modelList: [],
      pointList: [],
      operatorList: [],
      cabinetTypeList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        const fullCity = [
          this.form.province,
          this.form.city,
          this.form.district
        ]
        this.form.fullCity = fullCity

        if (this.form.longitude) {
          this.position = {
            fullAddress: this.form.fullCity + this.form.address,
            longitude: this.form.longitude,
            latitude: this.form.latitude
          }
        } else {
          this.position = {}
        }
        this.isUpdate = true
      } else {
        this.form = {}
        this.position = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      this.queryBrandList()
      this.queryModelList()
    }
  },
  mounted() {
    this.headers[setting.tokenHeaderName] = setting.getToken()
    this.queryBrandList()
    this.queryModelList()
    this.queryPointList()
    this.queryOperatorList()
    this.queryCabinetType()
    this.getCabinetDictdata()
  },
  methods: {
    cancelPosition() {
      this.showPostion = false
    },
    setPosition(data) {
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      console.log(this.form)
      this.showPostion = false
    },
    // 地图选点
    coordinate() {
      this.postion = this.form
      this.showPostion = true
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          chargeCabinetApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    created() {
      this.initData()
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 获取换电柜业务状态
    getCabinetDictdata() {
      dictDataApi
        .getDictData({
          dictCode: 'batteryState'
        })
        .then((res) => {
          if (res.code === 0) {
            this.batteryStateList = res.data
          } else {
            this.$error(res.msg)
          }
        })
        .catch((e) => {
          this.$error(e.message)
        })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.form.brand = null
      this.form.model = null
      chargeCabinetModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询换电柜厂家List */
    queryBrandList() {
      if (this.form.operatorId) {
        chargeCabinetModelApi
          .brand({ operatorId: this.form.operatorId })
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        chargeCabinetModelApi
          .brand()
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    /* 查询换电柜型号List */
    queryModelList() {
      if (this.form.operatorId && this.form.brand) {
        chargeCabinetModelApi
          .model({
            operatorId: this.form.operatorId,
            brandName: this.form.brand
          })
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        chargeCabinetModelApi
          .model()
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 监听厂家改变
    handleBrandChange(value) {
      this.form.model = null
      // 查找当前厂家充电柜型号
      chargeCabinetModelApi
        .model({ brandName: value, operatorId: this.form.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // /* 查询换电柜型号List */
    // queryModleList() {

    // },
    /* 查询所属运营商List */
    queryOperatorList() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },

    /* 查询所属租赁点pointList */
    queryPointList() {
      pointListApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询充电柜协议类型 */
    queryCabinetType() {
      chargeCabinetApi
        .cabinetType()
        .then((res) => {
          if (res.code === 0) {
            this.cabinetTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    checkAllChange(e) {
      Object.assign(this.form, {
        modulesArray: e.target.checked ? this.moduleOptions : [],
        indeterminate: false
      })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.form[name] = res.data.location
            this.fileList[0] = {
              uid: '-4',
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    handleClose(val) {
      if (val === 1) {
        this.startOpen = false
      } else {
        this.endOpen = false
      }
    }
  }
}
</script>

<style scoped>
.Photo {
  width: 100%;
}
</style>
